import homeVid from "../assets/home_vid.mp4";

import chemworld from "../assets/chemworld.png";

import { social } from "../constants/social";
import useWindowDimensions from "../hooks/useWindowDimensions";

const Home = () => {
  const { width } = useWindowDimensions();

  return (
    <div
      id="home"
      className="min-vh-100 d-flex flex-column justify-content-center align-items-center text-center position-relative"
      style={{ marginTop: width < 992 ? 130 : 50 }}
    >
      <video
        loop
        autoPlay="autoplay"
        muted
        playsInline
        controls={false}
        id="homeVid"
        width="100%"
      >
        <source src={homeVid} type="video/mp4" />
      </video>
      <div className="vidOverlay" />
      <div className="container" style={{ zIndex: 1 }}>
        <h1 className="primary-h" data-aos="fade-down" data-aos-duration="700">
          Welcome To
          <br />
          <img
            src={chemworld}
            alt="ChemWorld"
            width={width > 992 ? "45%" : "60%"}
          />
        </h1>
        <br />
        <p className="primary-p" data-aos="fade-down" data-aos-duration="700">
          Hand Drawn Artwork Granting Access to the ChemWorld Metaverse.
          <br />A Web3 Multiplayer P2E Immersive VR Real World Experience.
        </p>
        <br />
        <div
          className="row justify-content-center my-3 gap-md-0 gap-4"
          data-aos="fade-down"
          data-aos-duration="700"
          data-aos-delay="100"
        >
          <a
            className="col-md-auto"
            href="https://tally.so/r/wMXZ6A"
            rel="noopener noreferrer"
            target="_blank"
          >
            <button className="primary-btn">Beta Access Open</button>
          </a>
          <a
            className="col-md-auto"
            href={social[3].link}
            rel="noopener noreferrer"
            target="_blank"
          >
            <button className="primary-btn accent-btn">Join our Discord</button>
          </a>
          <div className="col-md-auto">
            <button
              className="primary-btn"
              data-bs-toggle="modal"
              data-bs-target="#homeVidModal"
            >
              Play Trailer
            </button>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="homeVidModal"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div
            className="modal-content position-relative"
            style={{ background: "transparent", zIndex: 9 }}
          >
            <button
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{
                outline: "none",
                border: "none",
                background: "transparent",
                position: "absolute",
                top: 15,
                right: 15,
                zIndex: 99,
                fontSize: 18,
                cursor: "pointer",
              }}
            >
              <i
                className="bi bi-x-circle-fill"
                style={{ color: "var(--light)", cursor: "pointer" }}
              ></i>
            </button>
            <div
              className="modal-body"
              style={{
                backgroundColor: "rgba(var(--dark-rgb), 0.8)",
                border: "1px solid rgba(var(--light-rgb), 0.25)",
                padding: 20,
                borderRadius: 15,
              }}
            >
              <div
                id="trailer"
                data-aos="fade-up"
                data-aos-delay="250"
                data-aos-duration="700"
              >
                <iframe
                  title="chembeast trailer"
                  pause
                  style={{
                    width: "100%",
                    height: "600px",
                    borderRadius: 20,
                  }}
                  src="https://www.youtube.com/embed/jTQ-xUo-3W8"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
