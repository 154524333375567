import img1 from "../assets/asset_1.png";
import img2 from "../assets/asset_2.png";
import img3 from "../assets/asset_3.png";
import img4 from "../assets/asset_4.png";
import img5 from "../assets/asset_5.png";
import img6 from "../assets/asset_7.png";
import img7 from "../assets/asset_8.png";
import img8 from "../assets/asset_10.png";

const NFTs = () => {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center text-center"
      style={{
        backgroundColor: "rgba(var(--dark-rgb), 0.9)",
      }}
    >
      <div className="container mt-5 pt-5">
        <h1
          className="my-5 pt-3 primary-h"
          data-aos="fade-down"
          data-aos-duration="700"
        >
          ChemBeasts Showcase
        </h1>
        <br />
        <div className="row justify-content-center nft-showcase mb-5 pb-4">
          <div className="col-md-auto nft-showcase-img">
            <img src={img1} alt="nft" />
          </div>
          <div className="col-md-auto nft-showcase-img">
            <img src={img2} alt="nft" />
          </div>
          <div className="col-md-auto nft-showcase-img">
            <img src={img3} alt="nft" />
          </div>
          <div className="col-md-auto nft-showcase-img">
            <img src={img4} alt="nft" />
          </div>
          <div className="col-md-auto nft-showcase-img">
            <img src={img5} alt="nft" />
          </div>
          <div className="col-md-auto nft-showcase-img">
            <img src={img6} alt="nft" />
          </div>
          <div className="col-md-auto nft-showcase-img">
            <img src={img7} alt="nft" />
          </div>
          <div className="col-md-auto nft-showcase-img">
            <img src={img8} alt="nft" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NFTs;
