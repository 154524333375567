import { Link } from "react-router-dom";

const BetaSubmission = () => {
  return (
    <div
      id="about"
      className="min-vh-100 d-flex flex-column justify-content-center align-items-center text-center"
    >
      <div className="container justify-content-center">
        <h1 className="primary-h" data-aos="fade-down" data-aos-duration="700">
          Thank you <br />
          for filling out the
          <br />
          Beta Key Access form.
          <br />
          <div
            className="primary-h"
            style={{ fontSize: 32, color: "var(--light)" }}
          >
            Limited keys will be available.
          </div>
          <div
            className="primary-h"
            style={{ fontSize: 32, color: "var(--light)", marginTop: -20 }}
          >
            Are you ready for ChemWorld to rock your world?
          </div>
        </h1>
        <Link to="/">
          <button
            className="primary-btn mt-4"
            data-aos="fade-down"
            data-aos-delay="200"
            data-aos-duration="700"
          >
            Yes!
          </button>
        </Link>
      </div>
    </div>
  );
};

export default BetaSubmission;
