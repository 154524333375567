import RoadmapCard from "../components/roadmap.component";

const Roadmap = () => {
  return (
    <div
      id="roadmap"
      className="d-flex flex-column justify-content-center align-items-center my-5 py-5"
      style={{ scrollMarginTop: 100 }}
    >
      <div className="container">
        <h1
          className="primary-h"
          data-aos="fade-down"
          data-aos-delay="100"
          data-aos-duration="700"
        >
          ROADMAP
        </h1>
        <br />
        <div className="roadmap">
          <RoadmapCard
            title={"PHASE 1"}
            heading={<h1>Phase 1</h1>}
            desc={
              <p>
                Website creation
                <br />
                Social media campaign
                <br />
                Pre marketing campaign
                <br />
                ChemWorld Limited Beta Access
                <br />
                Free NFT giveaway
                <br />
                Whitelist competition
                <br />
              </p>
            }
          />
          <RoadmapCard
            title={"PHASE 2"}
            heading={<h1>Phase 2</h1>}
            desc={
              <p>
                Mint date confirmed
                <br />
                NFT/Opensea listings
                <br />
                NFT Marketplace Launch
                <br />
                Secret ChemBeasts Goodies
                <br />
                Project Ambassadors
                <br />
                Project Collaborations
                <br />
                Social media campaign
                <br />
              </p>
            }
          />
          <RoadmapCard
            title={"PHASE 3"}
            heading={<h1>Phase 3</h1>}
            desc={
              <p>
                NFT staking
                <br />
                NFT marketing
                <br />
                Chemworld whitelist competition
                <br />
                Chemworld rewards to NFT holders
                <br />
              </p>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
