const Explore = () => {
  return (
    <div
      id="explore-chemcity"
      className="d-flex flex-column justify-content-center align-items-center text-center"
      style={{
        scrollMarginTop: 60,
        backgroundColor: "rgba(var(--dark-rgb), 0.9)",
        padding: "50px 0px",
      }}
    >
      <div className="container justify-content-center my-4">
        <h1 className="primary-h" data-aos="fade-down" data-aos-duration="700">
          Explore Some Of
          <br />
          Chem City
        </h1>
        <div
          id="trailer"
          className="mt-5"
          data-aos="fade-down"
          data-aos-delay="150"
          data-aos-duration="700"
        >
          <iframe
            width="100%"
            height="600px"
            src="https://www.youtube.com/embed/Vh_co4zKsLw?controls=0"
            title="explore chemcity"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Explore;
