const Footer = () => {
  return (
    <footer>
      <div className="text-center">
        <small>
          © <a href="/">ChemWorld</a>
        </small>
      </div>
    </footer>
  );
};

export default Footer;
