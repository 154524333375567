import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { connect } from "../redux/blockchain/blockchainActions";
import { useSelector } from "react-redux";
import { fetchData } from "../redux/data/dataActions";

const Minting = () => {
  const disabled = true;
  const dispatch = useDispatch();
  const data = useSelector((state) => state.data);
  const blockchain = useSelector((state) => state.blockchain);

  const [claimingNFT, setNFTClaimed] = useState(false);
  const [isConnected, setConnected] = useState(false);

  const [buyQuantity, setBuyQuantity] = useState(1);
  const [dataFetched, setDataFetched] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [connectBtnText, setConnectBtnText] = useState("Connect Wallet");
  const [displayMsg, setDisplayMsg] = useState("");

  const toastMessage = (message) => toast(message);

  const [loadedConfig, setLoadedConfig] = useState(null);

  useEffect(() => {
    (async function () {
      await fetch("/config/config.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (response) => {
          const config = await response.json();
          setLoadedConfig(config);
          setDataFetched(1);
        })
        .catch((e) => {
          console.log(e);
        });
    })();
  }, []);

  const handleBuyQuantityDecrement = () => {
    buyQuantity > 1 && setBuyQuantity(buyQuantity - 1);
  };

  const handleBuyQuantityIncrement = () => {
    buyQuantity < loadedConfig.MAX_BUY_QUANTITY &&
      setBuyQuantity(buyQuantity + 1);
  };

  const handleConnect = (e) => {
    e.preventDefault();
    if (blockchain.account === null) {
      setLoading(true);
      !isConnected && setConnectBtnText("Connecting...");
      dispatch(connect()).then((response) => {
        if (response !== undefined) {
          setConnected(false);
          setLoading(false);
          setConnectBtnText("Connect Wallet");
          toastMessage(response.payload);
        }
      });
      getData();
    }
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
      setConnected(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [blockchain.account]);

  useEffect(() => {
    displayMsg !== "" && toastMessage(displayMsg);
  }, [displayMsg]);

  const handleBuyNFT = (e) => {
    e.preventDefault();
    claimNFTs();
    setLoading(true);
    getData();
  };

  const claimNFTs = () => {
    let cost = loadedConfig.WEI_COST;
    let gasLimit = loadedConfig.GAS_LIMIT;
    let totalCostWei = String(cost * buyQuantity);
    let totalGasLimit = String(gasLimit * buyQuantity);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setDisplayMsg(`Minting your ${loadedConfig.NFT_NAME}...`);
    setNFTClaimed(true);
    console.log(loadedConfig.CONTRACT_ADDRESS);
    console.log(blockchain.account);
    console.log(totalCostWei);
    var gasAmount = blockchain.smartContract.methods
      .mint(buyQuantity)
      .estimateGas({
        to: loadedConfig.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .then((estimatedGas, err) => {
        console.log("----------------");
        console.log(err);
        console.log("----------------");

        console.log(gasAmount);
        estimatedGas = Math.round(estimatedGas * 1.2);
        console.log("gas limit estimation = " + estimatedGas + " units");
        console.log({
          from: loadedConfig.CONTRACT_ADDRESS,
          value: totalCostWei,
        });

        blockchain.smartContract.methods
          .mint(buyQuantity)
          .send({
            to: loadedConfig.CONTRACT_ADDRESS,
            from: blockchain.account,
            value: totalCostWei,
            gas: estimatedGas,
          })
          .once("error", (err) => {
            console.log(err);
            setDisplayMsg(
              "Sorry, something went wrong please try again later."
            );
            setNFTClaimed(false);
          })
          .then((receipt) => {
            console.log(receipt);
            setDisplayMsg(`WOW, the ${loadedConfig.NFT_NAME} is yours!`);
            setNFTClaimed(false);
            setLoading(false);
            dispatch(fetchData(blockchain.account));
          });
      })
      .catch(function (error) {
        console.log("Estimategas() - catch error");
        console.log(error);

        if (error.message.includes("max NFT per address")) {
          setDisplayMsg(
            `Error: Max ${loadedConfig.MAX_BUY_QUANTITY} NFT per address allowed!`
          );
          setNFTClaimed(false);
        }
        if (error.message.includes("insufficient funds")) {
          setDisplayMsg(`Error: Insufficient funds for gas + price`);
          setNFTClaimed(false);
        }
      });
  };

  useEffect(() => {
    const body = document.getElementsByTagName("body")[0];
    body.style.backgroundImage = "none";
  }, []);

  return (
    <div className="min-vh-100">
      {dataFetched === 1 && (
        <div className="container centered">
          <div className="mint-container">
            <div
              className="content"
              data-aos="fade-down"
              data-aos-delay="250"
              data-aos-duration="700"
            >
              <div className="qty">
                {data.totalSupply}/{loadedConfig.MAX_SUPPLY}
              </div>
              <div>
                <a
                  href={loadedConfig.SCAN_LINK}
                  target="_blank"
                  rel="noreferrer"
                  style={{ all: "unset" }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      letterSpacing: 1,
                      textDecoration: "underline",
                      fontWeight: 400,
                      lineHeight: 3,
                      cursor: "pointer",
                    }}
                  >
                    WALLET ADDRESS
                  </span>
                </a>
              </div>
              {disabled ? (
                <div className="cost">
                  Minting will be available soon!
                  <br />
                  <span style={{ color: "var(--primary)" }}>Stay tuned!</span>
                </div>
              ) : data.totalSupply >= loadedConfig.MAX_SUPPLY ? (
                <div>
                  <h1>The sale has ended.</h1>
                  <h3>You can still find {loadedConfig.NFT_NAME} on</h3>
                  <a
                    target={"_blank"}
                    href={loadedConfig.MARKETPLACE_LINK}
                    rel="noreferrer"
                  >
                    <h3>{loadedConfig.MARKETPLACE}</h3>
                  </a>
                </div>
              ) : (
                <div>
                  <div className="cost">
                    MINT {buyQuantity}{" "}
                    <span style={{ color: "var(--primary)" }}>
                      {loadedConfig.NFT_NAME}
                    </span>
                    <br />
                    for{" "}
                    <span style={{ color: "var(--primary)" }}>
                      {Math.round(
                        (loadedConfig.DISPLAY_COST * buyQuantity +
                          Number.EPSILON) *
                          1000
                      ) / 1000}{" "}
                      {loadedConfig.NETWORK.SYMBOL}
                    </span>
                    <br />
                    <span
                      style={{
                        fontSize: 12,
                        letterSpacing: 3,
                        fontWeight: 600,
                      }}
                    >
                      EXCLUDING GAS FEES
                    </span>
                  </div>
                  <div>
                    {isConnected ? (
                      <div className="hstack justify-content-center gap-4">
                        <h3>{displayMsg}</h3>
                        <div className="qty-container">
                          <button
                            className="sub"
                            onClick={handleBuyQuantityDecrement}
                          >
                            -
                          </button>
                          <div>{buyQuantity}</div>
                          <button
                            className="add"
                            onClick={handleBuyQuantityIncrement}
                          >
                            +
                          </button>
                        </div>
                        <button
                          disabled={claimingNFT ? true : false}
                          onClick={handleBuyNFT}
                          className="primary-btn"
                          style={{ padding: "8px 25px", borderRadius: 5 }}
                        >
                          {claimingNFT ? "Minting..." : "Mint"}
                        </button>
                      </div>
                    ) : (
                      <div>
                        <span
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                            color: "var(--light)",
                          }}
                        >
                          Connect to the {loadedConfig.NETWORK.NAME} network
                        </span>
                        <div className="hstack m-3 justify-content-center">
                          <button
                            onClick={handleConnect}
                            disabled={isLoading}
                            className="primary-btn"
                          >
                            {connectBtnText}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Minting;
