import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import { social } from "../constants/social";

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark fixed-top">
      <div className="container">
        <div className="d-flex flex-grow-1">
          <a
            className="navbar-brand"
            href="/"
            data-aos="fade-down"
            data-aos-delay="50"
            data-aos-duration="700"
          >
            <img src={logo} width="80px" alt="logo" />
          </a>
          <div className="hstack ms-auto">
            <button
              className="navbar-toggler"
              style={{
                margin: "auto",
                border: "none",
                background: "transparent",
                outline: "none",
                boxShadow: "none",
              }}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbar"
            >
              <span>
                <i className="bi bi-three-dots-vertical"></i>
              </span>
            </button>
          </div>
        </div>
        <div
          className="collapse navbar-collapse flex-grow-1 text-right"
          id="navbar"
        >
          <ul className="navbar-nav ms-auto flex-nowrap">
            <li
              className="nav-item"
              data-aos="fade-down"
              data-aos-delay="150"
              data-aos-duration="700"
            >
              <a href="/#home" className="nav-link">
                Home
              </a>
            </li>
            <li
              className="nav-item"
              data-aos="fade-down"
              data-aos-delay="200"
              data-aos-duration="700"
            >
              <a href="/#about" className="nav-link">
                About
              </a>
            </li>
            <li
              className="nav-item"
              data-aos="fade-down"
              data-aos-delay="200"
              data-aos-duration="700"
            >
              <Link to="/utilities" className="nav-link">
                Utilities
              </Link>
            </li>
            <li
              className="nav-item"
              data-aos="fade-down"
              data-aos-delay="200"
              data-aos-duration="700"
            >
              <Link to="/nfts" className="nav-link">
                NFTs
              </Link>
            </li>
            <li
              className="nav-item"
              data-aos="fade-down"
              data-aos-delay="250"
              data-aos-duration="700"
            >
              <a href="/#team" className="nav-link">
                Team
              </a>
            </li>
            <li
              className="nav-item"
              data-aos="fade-down"
              data-aos-delay="300"
              data-aos-duration="700"
            >
              <a href="/#faq" className="nav-link">
                FAQ
              </a>
            </li>
            <li
              className="nav-item"
              data-aos="fade-down"
              data-aos-delay="350"
              data-aos-duration="700"
            >
              <a href="/#roadmap" className="nav-link">
                Roadmap
              </a>
            </li>
            {social.map((item, index) => (
              <li
                key={index}
                className="nav-item"
                data-aos="fade-down"
                data-aos-delay={350 + index * 50}
                data-aos-duration="700"
              >
                <a href={item.link} className="nav-link">
                  {item.icon}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
