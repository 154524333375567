import { useEffect } from "react";

import img1 from "../assets/gif_1.gif";
import img2 from "../assets/gif_2.gif";
import img3 from "../assets/gif_3.gif";
import img4 from "../assets/gif_4.gif";

import anim from "../assets/anim.mp4";
import anim2 from "../assets/anim2.mp4";
import anim3 from "../assets/anim3.mp4";
import anim4 from "../assets/anim4.mp4";
import useWindowDimensions from "../hooks/useWindowDimensions";

const About = () => {
  const { width } = useWindowDimensions();

  useEffect(() => {
    function counter(id, start, end, duration) {
      let obj = document.getElementById(id),
        current = start,
        range = end - start,
        increment = end > start ? 1 : -1,
        step = Math.abs(Math.floor(duration / range)),
        timer = setInterval(() => {
          current += increment;
          obj.textContent = current;
          if (current === end) {
            clearInterval(timer);
          }
        }, step);
    }

    counter("value1", 0, 7, 2750);
    counter("value2", 0, 123, 2000);
    counter("value3", 0, 9, 3000);
    counter("value4", 0, 16, 2500);
  }, []);

  return (
    <div
      id="about"
      className="d-flex flex-column justify-content-center align-items-center text-center"
      style={{
        scrollMarginTop: 60,
        backgroundColor: "rgba(var(--dark-rgb), 0.9)",
        padding: "100px 0px",
      }}
    >
      <div className="container">
        <h1 className="primary-h" data-aos="fade-down" data-aos-duration="700">
          About
          <br />
          ChemWorld
        </h1>
        <div className="hstack justify-content-center gap-3 mt-4">
          <video
            loop
            autoPlay="autoplay"
            muted
            playsInline
            controls={false}
            loading="lazy"
            fetchpriority="low"
            className="aboutVid"
            data-aos="fade-down"
            data-aos-delay="100"
            data-aos-duration="700"
          >
            <source src={anim} type="video/mp4" />
          </video>
          <video
            loop
            autoPlay="autoplay"
            muted
            playsInline
            controls={false}
            loading="lazy"
            fetchpriority="low"
            className="aboutVid"
            data-aos="fade-down"
            data-aos-delay="100"
            data-aos-duration="700"
          >
            <source src={anim2} type="video/mp4" />
          </video>
        </div>
        <div className="hstack justify-content-center gap-3 mt-3">
          <video
            loop
            autoPlay="autoplay"
            muted
            playsInline
            controls={false}
            loading="lazy"
            fetchpriority="low"
            className="aboutVid"
            data-aos="fade-down"
            data-aos-delay="100"
            data-aos-duration="700"
          >
            <source src={anim3} type="video/mp4" />
          </video>
          <video
            loop
            autoPlay="autoplay"
            muted
            playsInline
            controls={false}
            loading="lazy"
            fetchpriority="low"
            className="aboutVid"
            data-aos="fade-down"
            data-aos-delay="100"
            data-aos-duration="700"
          >
            <source src={anim4} type="video/mp4" />
          </video>
        </div>
        <div className="row justify-content-center my-5">
          <div
            className="col-md-auto"
            data-aos="fade-down"
            data-aos-delay="100"
            data-aos-duration="700"
          >
            <div className="count-card">
              <h1 className="value" id="value1">
                7
              </h1>
              <h1 className="name">Locations</h1>
            </div>
          </div>
          <div
            className="col-md-auto"
            data-aos="fade-up"
            data-aos-delay="150"
            data-aos-duration="700"
          >
            <div className="count-card">
              <h1 className="value" id="value2">
                123
              </h1>
              <h1 className="name">Assets</h1>
            </div>
          </div>
          <div
            className="col-md-auto"
            data-aos="fade-down"
            data-aos-delay="200"
            data-aos-duration="700"
          >
            <div className="count-card">
              <h1 className="value" id="value3">
                9
              </h1>
              <h1 className="name">Traits</h1>
            </div>
          </div>
          <div
            className="col-md-auto"
            data-aos="fade-up"
            data-aos-delay="250"
            data-aos-duration="700"
          >
            <div className="count-card">
              <h1 className="value" id="value4">
                16
              </h1>
              <h1 className="name">Characters</h1>
            </div>
          </div>
        </div>
        <br />
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-6 col-md-12 order-1 order-xl-0 my-auto">
            <p
              className="primary-p px-4"
              style={{ textAlign: "left", marginBottom: width < 992 && -60 }}
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="700"
            >
              The Future of NFT Blockchain Gaming. 100% True digital asset
              ownership. Gameplay and all of ChemWorld’s utilities are Available
              to all ChemBeasts NFT Holders. ChemWorld a true to life
              Revolutionary State of The Art Metaverse gaming platform with
              multiple cities, states and countries. Ability to travel within
              the game. Drive Cars, trucks, tanks, Boats or Fly Helicopters to
              other Districts including your Mansion, Stash house, casino’s and
              more. Display your NFT’s within your properties you own or rent.
              Advertising packages will be available for Verified projects, and
              more.<strong> Hold a ChemBeast and you're in the game</strong>
            </p>
          </div>
          <div className="col-xl-4 col-lg-6 col-md-12 px-4 order-0 order-xl-1">
            <div
              className="row"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="700"
            >
              <div className="p-2 col-6 about-img">
                <img
                  className="img-fluid"
                  loading="lazy"
                  fetchpriority="low"
                  src={img1}
                  alt="img1"
                />
              </div>
              <div className="p-2 col-6 about-img">
                <img
                  className="img-fluid"
                  loading="lazy"
                  fetchpriority="low"
                  src={img2}
                  alt="img2"
                />
              </div>
            </div>
            <div
              className="row"
              data-aos="fade-left"
              data-aos-delay="150"
              data-aos-duration="700"
            >
              <div className="p-2 col-6 about-img">
                <img
                  className="img-fluid"
                  loading="lazy"
                  fetchpriority="low"
                  src={img3}
                  alt="img3"
                />
              </div>
              <div className="p-2 col-6 about-img">
                <img
                  className="img-fluid"
                  loading="lazy"
                  fetchpriority="low"
                  src={img4}
                  alt="img4"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
