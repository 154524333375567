import FAQCard from "../components/faq.component";

const FAQ = () => {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      id="faq"
      style={{ minHeight: "100vh", scrollMarginTop: 40 }}
    >
      <div className="container d-flex flex-column justify-content-center align-items-center">
        <h1 className="primary-h" data-aos="fade-down" data-aos-duration="700">
          FAQ
        </h1>
        <br />
        <div
          className="faq-card"
          data-aos="fade-down"
          data-aos-delay="100"
          data-aos-duration="700"
        >
          <FAQCard
            title="What are ChemBeasts"
            text="ChemBeasts are Unique hand drawn NFTs bringing you exclusive access to the Chemworld P2E game and future NFT & token rewards. All ChemBeasts holders will benefit from complete access to ChemWorld's Revolutionary Metaverse Gaming Platform + Weapon Packs, Land, Vehicles, Skins and more."
          />
        </div>
        <div
          className="faq-card"
          data-aos="fade-down"
          data-aos-delay="150"
          data-aos-duration="700"
        >
          <FAQCard
            title="What is the total supply?"
            text="There will be a total of 7,777 ChemBeasts."
          />
        </div>
        <div
          className="faq-card"
          data-aos="fade-down"
          data-aos-delay="200"
          data-aos-duration="700"
        >
          <FAQCard
            title="What is the network?"
            text="The network will be Ethereum (ETH)."
          />
        </div>
        <div
          className="faq-card"
          data-aos="fade-down"
          data-aos-delay="250"
          data-aos-duration="700"
        >
          <FAQCard title="When will the ChemBeasts Launch?" text="TBA" />
        </div>
        <div
          className="faq-card"
          data-aos="fade-down"
          data-aos-delay="300"
          data-aos-duration="700"
        >
          <FAQCard
            title="What is the mint price?"
            text="Mint price will soon be announced for WL and Public."
          />
        </div>
        <div
          className="faq-card"
          data-aos="fade-down"
          data-aos-delay="350"
          data-aos-duration="700"
        >
          <FAQCard
            title="How many can I mint per wallet?"
            text="You can mint a total of (placeholder) ChemBeasts per wallet."
          />
        </div>
      </div>
    </div>
  );
};

export default FAQ;
