import img1 from "../assets/asset_1.png";
import img2 from "../assets/asset_2.png";
import img3 from "../assets/asset_3.png";
import img4 from "../assets/asset_4.png";
import img5 from "../assets/asset_5.png";
import img6 from "../assets/asset_8.png";
import q3 from "../assets/Q3.png";

import TeamCard from "../components/team.component";

const Team = () => {
  return (
    <div
      id="team"
      className="d-flex flex-column justify-content-center align-items-center my-5"
      style={{
        scrollMarginTop: 100,
        backgroundColor: "rgba(var(--dark-rgb), 0.9)",
        padding: "100px 0px",
      }}
    >
      <div className="container mb-5 pb-5">
        <h1 data-aos="fade-down" data-aos-duration="700" className="primary-h">
          Team
        </h1>
        <br />
        <p
          className="primary-p px-4 w-75 mx-auto"
          data-aos="fade-down"
          data-aos-delay="100"
          data-aos-duration="700"
        >
          Our fortunate Team of highly skilled Developers, 3D Designers,
          Influencers, Investors, Marketers all together make a recipe for the
          Dream Team effect. Through our journey we have Bled the color Green
          for ChemWorld and ChemBeats while we continue to do so with our
          Holders in mind. Our decades of experience give us a giant edge in the
          NFT Blockchain Ecosystem.
        </p>
        <br />
        <div className="row justify-content-center">
          <div
            className="col-md-auto"
            data-aos="fade-up"
            data-aos-delay="150"
            data-aos-duration="700"
          >
            <TeamCard
              source={img1}
              name={"Jason Roberts"}
              title={"Co-Founder and CEO"}
            />
          </div>
          <div
            className="col-md-auto"
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-duration="700"
          >
            <TeamCard
              source={img2}
              name={"Kyle G."}
              title={"Chief Technology Officer (CTO)"}
            />
          </div>
          <div
            className="col-md-auto"
            data-aos="fade-up"
            data-aos-delay="250"
            data-aos-duration="700"
          >
            <TeamCard
              source={img3}
              name={"Luke Thomas"}
              title={"Chief Financial Officer (CFO)"}
            />
          </div>
        </div>
        <br />
        <div className="row justify-content-center">
          <div
            className="col-md-auto"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="700"
          >
            <TeamCard
              source={img4}
              name={"Farshad S."}
              title={"Lead Game Developer"}
            />
          </div>
          <div
            className="col-md-auto"
            data-aos="fade-up"
            data-aos-delay="350"
            data-aos-duration="700"
          >
            <TeamCard source={img5} name={"Hasitha"} title={"NFT Developer"} />
          </div>
          <div
            className="col-md-auto"
            data-aos="fade-up"
            data-aos-delay="400"
            data-aos-duration="700"
          >
            <TeamCard
              source={img6}
              name={"Horace Owens"}
              title={"Head of Community"}
            />
          </div>
        </div>
      </div>
      <img
        src={q3}
        alt="Q3"
        style={{ width: "100vw", zIndex: 1, marginBottom: -100 }}
        loading="lazy"
        fetchpriority="low"
      />
    </div>
  );
};

export default Team;
