import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { Provider } from "react-redux";
import { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { ToastContainer } from "react-toastify";

import logo from "../assets/logo.png";
import store from "../redux/store";

import Navbar from "../components/navbar.component";
import App from "../App";
import Minting from "../pages/minting.page";
import Gameplay from "../pages/gameplay.page";
import NFTs from "../pages/nfts.page";
import BetaSubmission from "../pages/betaSubmission.page";
import Footer from "../components/footer.component";

const Routing = () => {
  const [showLoader, setShowLooader] = useState(true);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  useEffect(() => {
    setInterval(() => {
      setShowLooader(false);
    }, 1600);
  }, []);

  return (
    <Router>
      <Provider store={store}>
        {showLoader ? (
          <div className="progress-container">
            <img src={logo} className="loading-logo" alt="loader" />
            <div className="progress">
              <div className="progress-value"></div>
            </div>
          </div>
        ) : (
          <>
            <Navbar />
            <ToastContainer
              position="bottom-left"
              autoClose={6000}
              hideProgressBar
              closeOnClick
              theme="dark"
              toastStyle={{
                bottom: 10,
                border: "1px solid rgba(var(--primary-rgb), 0.75)",
                boxShadow:
                  "0 0 60px 5px rgba(var(--primary-rgb), 0.2), 0 0 50px 0px rgba(var(--secondary-rgb), 0.6)",
                backgroundImage:
                  "linear-gradient(to right, rgba(var(--secondary-rgb), 0.3), rgba(var(--primary-rgb), 0.6)",
                color: "var(--light)",
                borderRadius: 50,
                textAlign: "center",
                fontSize: 16,
              }}
              rtl={false}
              draggable
              pauseOnHover
            />
            <Routes>
              <Route exact path="*" element={<Navigate to="/" />} />
              <Route exact path="/" element={<App />} />
              <Route path="/utilities" element={<Gameplay />} />
              <Route path="/nfts" element={<NFTs />} />
              <Route path="/mint" element={<Minting />} />
              <Route path="/beta-submission" element={<BetaSubmission />} />
            </Routes>
            <Footer />
          </>
        )}
      </Provider>
    </Router>
  );
};

export default Routing;
