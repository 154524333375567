const RoadmapCard = ({ title, heading, desc }) => {
  return (
    <div
      className="roadmap-item"
      data-title={title}
      data-aos="fade-down"
      data-aos-delay="300"
      data-aos-duration="700"
    >
      {heading}
      {desc}
    </div>
  );
};

export default RoadmapCard;
