import img5 from "../assets/play.png";
import img6 from "../assets/trade.png";
import img7 from "../assets/earn.png";
import img8 from "../assets/stake.gif";

import useWindowDimensions from "../hooks/useWindowDimensions";

const Gameplay = () => {
  const { width } = useWindowDimensions();

  return (
    <div
      id="gameplay"
      className="d-flex flex-column justify-content-center align-items-center text-center"
      style={{
        backgroundColor: "rgba(var(--dark-rgb), 0.9)",
      }}
    >
      <div className="container mt-5 pt-5">
        <h1
          className="my-5 pt-3 primary-h"
          data-aos="fade-down"
          data-aos-duration="700"
        >
          Utilities
        </h1>
        <br />
        <div className="row justify-content-center utility-card">
          <div
            className="col-lg-4 col-6 order-1 order-xl-0"
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-duration="700"
          >
            <h1>Play</h1>
            <p className="my-2 mb-4">
              To find out more about gameplay, click the button below.
            </p>
            <button
              className="primary-btn"
              data-bs-toggle="modal"
              data-bs-target="#gameplayModal"
            >
              About Gameplay
            </button>
          </div>
          <div
            className="col-md-auto utility-img order-0 order-xl-1"
            data-aos="fade-left"
            data-aos-delay="200"
            data-aos-duration="700"
          >
            <img src={img5} alt="" loading="lazy" fetchpriority="low" />
          </div>
          <div
            className="modal fade"
            id="gameplayModal"
            role="dialog"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div
                className="modal-content position-relative"
                style={{ background: "transparent" }}
              >
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{
                    outline: "none",
                    border: "none",
                    background: "transparent",
                    position: "absolute",
                    top: 15,
                    right: 15,
                    zIndex: 99,
                    fontSize: 18,
                    cursor: "pointer",
                  }}
                >
                  <i
                    className="bi bi-x-circle-fill"
                    style={{ color: "var(--light)", cursor: "pointer" }}
                  ></i>
                </button>
                <div
                  className="modal-body"
                  style={{
                    backgroundColor: "rgba(var(--dark-rgb), 0.8)",
                    border: "1px solid rgba(var(--light-rgb), 0.25)",
                    padding: 20,
                    borderRadius: 15,
                  }}
                >
                  <p
                    className="primary-p"
                    style={{ textAlign: "left", fontSize: 16 }}
                  >
                    Access to all ChemBeast NFT holders and experience
                    ChemWorld.
                  </p>
                  <ul
                    className="primary-p"
                    style={{ textAlign: "left", fontSize: 16 }}
                  >
                    <li>The most realistic Metaverse game to date</li>
                    <li>Travel to different cities, states, and countries</li>
                    <li>
                      Drive cars, trucks, tanks, boats and fly helicopters.
                    </li>
                    <li>
                      All ChemBeasts holders will receive rewards of Assets for
                      ChemWorld to benefit the most from our unparalleled
                      Blockchain gaming experience.
                    </li>
                    <li>
                      All ChemWorld assets including ChemBeasts will be
                      Available for trade on all marketplaces as well as our own
                      Marketplace, Which will also be available within the game.
                    </li>
                  </ul>
                  <p
                    className="primary-p"
                    style={{ textAlign: "left", fontSize: 16 }}
                  >
                    ChemWorld Maps
                  </p>
                  <ul
                    className="primary-p"
                    style={{ textAlign: "left", fontSize: 16 }}
                  >
                    <li>The Boathouse</li>
                    <li>ChemCity</li>
                    <li>ChemVerse</li>
                    <li>Japan</li>
                  </ul>
                  <p
                    className="primary-p"
                    style={{ textAlign: "left", fontSize: 16 }}
                  >
                    Start RPG team missions, Play full out multiplayer mode,
                    Battle mode or activate Frenzy Mode and go all out Killing
                    the Flesh eating Zombies and Maniac killers while earning
                    tokens. Level UP? More Modes are being added.
                    <br />
                    <br />
                    Chat with your friends or Team players in real time within
                    the game (Voice Chat ability)
                    <br />
                    <br />
                    Built in Digital Mobile Phones for messaging. (Virtual
                    Custom Phone number creation) All utilized and encrypted
                    using Blockchain technology. -Oculus compatibility (coming
                    soon)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center utility-card py-5">
          <div
            className="col-6 order-1"
            data-aos="fade-left"
            data-aos-delay="200"
            data-aos-duration="700"
          >
            <h1>Trade</h1>
            <p className="my-2 mb-4">
              To find out more about trades, click the button below.
            </p>
            <button
              className="primary-btn"
              data-bs-toggle="modal"
              data-bs-target="#tradeModal"
            >
              About Trade
            </button>
          </div>
          <div
            className="col-md-auto utility-img order-0"
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-duration="700"
          >
            <img src={img6} alt="" loading="lazy" fetchpriority="low" />
          </div>
          <div
            className="modal fade"
            id="tradeModal"
            role="dialog"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div
                className="modal-content position-relative"
                style={{ background: "transparent" }}
              >
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{
                    outline: "none",
                    border: "none",
                    background: "transparent",
                    position: "absolute",
                    top: 15,
                    right: 15,
                    zIndex: 99,
                    fontSize: 18,
                    cursor: "pointer",
                  }}
                >
                  <i
                    className="bi bi-x-circle-fill"
                    style={{ color: "var(--light)", cursor: "pointer" }}
                  ></i>
                </button>
                <div
                  className="modal-body"
                  style={{
                    backgroundColor: "rgba(var(--dark-rgb), 0.8)",
                    border: "1px solid rgba(var(--light-rgb), 0.25)",
                    padding: 20,
                    borderRadius: 15,
                  }}
                >
                  <ul
                    className="primary-p"
                    style={{
                      textAlign: width > 992 ? "right" : "center",
                      fontSize: 16,
                      listStylePosition: "inside",
                    }}
                  >
                    <li>Become a Property Owner</li>
                    <li>Own, Buy, Rent Property</li>
                    <li>
                      Display any one of your NFT collections in any property
                      you own or rent
                    </li>
                    <li>
                      100% Digital Ownership. True ownership. No third party
                      needed to transfer assets between players.
                    </li>
                    <li>
                      Landlords can earn even more with weekly/monthly rent
                      payments.
                    </li>
                    <li>
                      Low Gas Fees: Swapping tokens for Ethereum or items within
                      the game is easy and cheap
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center utility-card py-5">
          <div
            className="col-6 order-1 order-xl-0"
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-duration="700"
          >
            <h1>Earn</h1>
            <p className="my-2 mb-4">
              To find out more about rewards, click the button below.
            </p>
            <button
              className="primary-btn"
              data-bs-toggle="modal"
              data-bs-target="#earnModal"
            >
              About Rewards
            </button>
          </div>
          <div
            className="col-md-auto utility-img order-0 order-xl-1"
            data-aos="fade-left"
            data-aos-delay="200"
            data-aos-duration="700"
          >
            <img src={img7} alt="" loading="lazy" fetchpriority="low" />
          </div>
          <div
            className="modal fade"
            id="earnModal"
            role="dialog"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div
                className="modal-content position-relative"
                style={{ background: "transparent" }}
              >
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{
                    outline: "none",
                    border: "none",
                    background: "transparent",
                    position: "absolute",
                    top: 15,
                    right: 15,
                    zIndex: 99,
                    fontSize: 18,
                    cursor: "pointer",
                  }}
                >
                  <i
                    className="bi bi-x-circle-fill"
                    style={{ color: "var(--light)", cursor: "pointer" }}
                  ></i>
                </button>
                <div
                  className="modal-body"
                  style={{
                    backgroundColor: "rgba(var(--dark-rgb), 0.8)",
                    border: "1px solid rgba(var(--light-rgb), 0.25)",
                    padding: 20,
                    borderRadius: 15,
                  }}
                >
                  <ul
                    className="primary-p"
                    style={{
                      textAlign: "left",
                      padding: 0,
                      fontSize: 16,
                      listStylePosition: "inside",
                    }}
                  >
                    <li>ChemWorld The Metaverse of Gaming: Get Paid to Play</li>
                    <li>
                      Earn CMW Tokens while you play: The in-game currency of
                      ChemWorld
                    </li>
                    <li>
                      Buy, sell or swap any character or item in the Metaverse
                      with low gas fees
                    </li>
                    <li>
                      A Fully Integrated NFT Marketplace offers ease of use.
                    </li>
                  </ul>
                  <p
                    className="primary-p"
                    style={{
                      textAlign: "left",
                      fontSize: 16,
                    }}
                  >
                    CMW is ChemWorlds local currency a True in game currency
                    registered on the Ethereum Blockchain. You will accumulate
                    Tokens from all aspects of the game. Trades, Rentals. In
                    Multiplayer mode ability to Rob stash houses, raid homes,
                    mansions and more. (Digital Blockchain Security) if left
                    unprotected (Security systems and options are available)
                    Including boobie traps, streaming digital cameras and land
                    mines.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center utility-card py-5 mb-5">
          <div
            className="col-6 order-1"
            data-aos="fade-left"
            data-aos-delay="200"
            data-aos-duration="700"
          >
            <h1>Stake</h1>
            <p className="my-2 mb-4">
              To find out more about stakes, click the button below.
            </p>
            <button
              className="primary-btn"
              data-bs-toggle="modal"
              data-bs-target="#stakeModal"
            >
              About Stake
            </button>
          </div>
          <div
            className="col-md-auto utility-img order-0"
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-duration="700"
          >
            <img src={img8} alt="" loading="lazy" fetchpriority="low" />
          </div>
          <div
            className="modal fade"
            id="stakeModal"
            role="dialog"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div
                className="modal-content position-relative"
                style={{ background: "transparent" }}
              >
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{
                    outline: "none",
                    border: "none",
                    background: "transparent",
                    position: "absolute",
                    top: 15,
                    right: 15,
                    zIndex: 99,
                    fontSize: 18,
                    cursor: "pointer",
                  }}
                >
                  <i
                    className="bi bi-x-circle-fill"
                    style={{ color: "var(--light)", cursor: "pointer" }}
                  ></i>
                </button>
                <div
                  className="modal-body"
                  style={{
                    backgroundColor: "rgba(var(--dark-rgb), 0.8)",
                    border: "1px solid rgba(var(--light-rgb), 0.25)",
                    padding: 20,
                    borderRadius: 15,
                  }}
                >
                  <ul
                    className="primary-p"
                    style={{
                      textAlign: "left",
                      fontSize: 16,
                      listStylePosition: "inside",
                    }}
                  >
                    <li>Stake and Earn CMW Tokens</li>
                    <li>Earn CMW while you play in ChemWorld's Metaverse</li>
                    <li>Earn Daily Withdrawals From Our Mining Farm</li>
                    <li>Stake all ChemWorld related NFT’s</li>{" "}
                    <li>
                      Built in Node system. Nodes for ChemWorld will be
                      available shortly before official gameplay launch. (Custom
                      Control Panels included with every node)
                    </li>
                    <li>
                      Stake your Characters, Items and ChemWorld Related Assets
                    </li>
                    <li>The Metaverse of Gaming: Get Paid to Play </li>
                    <li>
                      Earn CMW Tokens while you play: The in-game currency of
                      ChemWorld
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-5 utility-card row justify-content-center">
          <button
            id="vidBtn"
            className="primary-btn accent-btn col-md-3 col-6"
            data-bs-toggle="modal"
            data-bs-target="#vidModal"
          >
            Play Trailer
          </button>
        </div>
        <div
          className="modal fade"
          id="vidModal"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div
              className="modal-content position-relative"
              style={{ background: "transparent" }}
            >
              <button
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{
                  outline: "none",
                  border: "none",
                  background: "transparent",
                  position: "absolute",
                  top: 15,
                  right: 15,
                  zIndex: 99,
                  fontSize: 18,
                  cursor: "pointer",
                }}
              >
                <i
                  className="bi bi-x-circle-fill"
                  style={{ color: "var(--light)", cursor: "pointer" }}
                ></i>
              </button>
              <div
                className="modal-body"
                style={{
                  backgroundColor: "rgba(var(--dark-rgb), 0.8)",
                  border: "1px solid rgba(var(--light-rgb), 0.25)",
                  padding: 20,
                  borderRadius: 15,
                }}
              >
                <div
                  id="trailer"
                  data-aos="fade-up"
                  data-aos-delay="250"
                  data-aos-duration="700"
                >
                  <iframe
                    title="chembeast trailer"
                    pause
                    style={{
                      width: "100%",
                      height: "600px",
                      borderRadius: 20,
                    }}
                    src="https://www.youtube.com/embed/jTQ-xUo-3W8"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gameplay;
