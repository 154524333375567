import MailchimpSubscribe from "react-mailchimp-subscribe";

import Form from "../components/form.component";
import useWindowDimensions from "../hooks/useWindowDimensions";

const EmailCollect = (props) => {
  const { width } = useWindowDimensions();

  const postUrl = `https://solutions.us14.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;

  return (
    <div
      id="email"
      className="d-flex flex-column justify-content-center align-items-center"
      style={{
        height: width > 992 && "40vh",
        backgroundColor: "rgba(var(--dark-rgb), 0.9)",
      }}
    >
      <div id="email-sub" className="container text-center py-5">
        <center>
          <h1
            className="primary-h"
            data-aos="fade-down"
            data-aos-duration="700"
            style={{
              fontSize: width > 992 ? 36 : 32,
              color: "var(--secondary)",
              fontWeight: 700,
            }}
          >
            Subscribe for Updates!
          </h1>
          <p
            data-aos="fade-down"
            data-aos-delay="100"
            data-aos-duration="700"
            className="primary-p"
          >
            Submit your email below to subscribe for our mailing list.
          </p>
          <div
            data-aos="fade-down"
            data-aos-delay="200"
            data-aos-duration="700"
          >
            <MailchimpSubscribe
              url={postUrl}
              render={({ subscribe, status, message }) => (
                <Form
                  status={status}
                  message={message}
                  onValidated={(formData) => subscribe(formData)}
                />
              )}
            />
          </div>
        </center>
      </div>
    </div>
  );
};

export default EmailCollect;
