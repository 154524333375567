const FAQCard = ({ title, text }) => {
  return (
    <>
      <input type="checkbox" id={title} />
      <label className="title" htmlFor={title}>
        {title}
      </label>
      <div className="text">
        <p dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    </>
  );
};

export default FAQCard;
