import { ParallaxProvider, Parallax } from "react-scroll-parallax";

import img1 from "./assets/asset_6.png";
import img2 from "./assets/asset_9.png";

import FAQ from "./pages/faq.page";
import Home from "./pages/home.page";
import About from "./pages/about.page";
import Explore from "./pages/explore.page";
import Team from "./pages/team.page";
import Roadmap from "./pages/roadmap.page";
import useWindowDimensions from "./hooks/useWindowDimensions";
import EmailCollect from "./pages/emailCollect.page";

const App = () => {
  const { width } = useWindowDimensions();

  return (
    <>
      <ParallaxProvider>
        <Home />
        <About />
        <Explore />
        {width > 992 && (
          <Parallax translateX={["-30%", "-5%"]}>
            <div
              className="parallax-img"
              data-aos="fade-right"
              data-aos-delay="200"
              data-aos-duration="700"
            >
              <img
                src={img1}
                alt="parallaximg"
                style={{ left: 0 }}
                loading="lazy"
                fetchpriority="low"
              />
            </div>
          </Parallax>
        )}
        <Roadmap />
        {width > 992 && (
          <Parallax translateX={["30%", "0%"]}>
            <div
              className="parallax-img"
              data-aos="fade-left"
              data-aos-delay="200"
              data-aos-duration="700"
            >
              <img
                src={img2}
                style={{ transform: "scaleX(-1) rotate(6deg)" }}
                alt="parallaximg"
                loading="lazy"
                fetchpriority="low"
              />
            </div>
          </Parallax>
        )}
        <Team />
        <FAQ />
        <EmailCollect />
      </ParallaxProvider>
    </>
  );
};

export default App;
