const TeamCard = ({ source, name, title, twitter }) => {
  return (
    <div className="team-card">
      <div className="header">
        <div className="content">
          <img
            className="avatar"
            src={source}
            alt={`${name}_avatar`}
            loading="lazy"
            fetchpriority="low"
          />
          <h1 className="fullname">{name}</h1>
          <h2 className="jobtitle">{title}</h2>
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
