import { useEffect, useState } from "react";

const Form = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
      email.indexOf("@") > -1 &&
      onValidated({
        MERGE0: email,
      });
  };

  useEffect(() => {
    if (status === "success") setEmail("");
  }, [status]);

  return (
    <form onSubmit={(e) => handleSubmit(e)} style={{ position: "relative" }}>
      {status === "sending" && (
        <div className="alert sending">
          Please wait...
          <br />
        </div>
      )}
      {status === "error" && (
        <div className="alert error">
          {message}
          <br />
        </div>
      )}
      {status === "success" && (
        <div className="alert success">
          Thank you for submitting your email!
          <br />
        </div>
      )}
      <div className="hstack justify-content-center gap-2">
        {status !== "success" && (
          <div className="form-group my-2">
            <input
              type="email"
              id="email"
              name="email"
              className="form-control shadow-none"
              placeholder="Email*"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        )}
        <br />
        <button
          className="primary-btn"
          type="submit"
          disabled={status === "success" || status === "sending"}
          style={{ marginLeft: 20 }}
        >
          {status === "success" ? "Success!" : "Submit"}
        </button>
      </div>
    </form>
  );
};

export default Form;
