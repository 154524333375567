export const social = [
  {
    link: "https://www.chembeasts.com",
    icon: <i className="bi bi-globe"></i>,
  },
  {
    link: "https://twitter.com/chembeasts",
    icon: <i className="bi bi-twitter"></i>,
  },
  {
    link: "https://instagram.com/chembeasts",
    icon: <i className="bi bi-instagram"></i>,
  },
  {
    link: "https://discord.gg/njF8MyX2yy",
    icon: <i className="bi bi-discord"></i>,
  },
];
